.chapter-marker {
  position: absolute;
  height: 6px;
  width: 4px;
  background-color: #003663;
  border-radius: 2px;
  pointer-events: auto;
  cursor: pointer;
}

.chapter-title-box {
  width: max-content;
  padding: 5px 0px;
  height: 20px;
  position: relative;
  bottom: -160px;
  color: #fff;
  white-space: nowrap;
  overflow: visible;
  text-shadow: 2px 2px 2px grey, -2px -2px 2px grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.chapter-title, .chapter-time {
  display: block;
  width: 100%;
  padding: 2px;
}

.vjs-vtt-thumbnail-display {
  display: flex;
  justify-content: center;
}
