.vjs-theme-kubicle {
  border-radius: 8px;
  overflow: hidden;
  background-color: #003663; /* VeniceBlue2 from KubicleColorsMui.js */
}

/* Fix black background pop-in */
.vjs-poster {
  background-color: transparent;
}

/* Control bar */
.vjs-theme-kubicle .vjs-control-bar {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  height: 105px;
  padding-top: 60px;
  font-size: 14px;
}

/* Progress bar */
.vjs-theme-kubicle .vjs-progress-control {
  position: absolute;
  width: 100%;

  /* Position and allow space above for easier tracking */
  bottom: 45px;
  height: 60px;
  padding-top: 45px;
}

.vjs-theme-kubicle .vjs-progress-holder {
  height: 5px;
  margin: 0;
}

.vjs-theme-kubicle .vjs-play-progress,
.vjs-theme-kubicle .vjs-load-progress {
  height: 2px;
}

.vjs-theme-kubicle .vjs-play-progress {
  background-color: white;
}

/* Remove current progress pip */
.vjs-theme-kubicle .vjs-play-progress::before {
  display: none;
}

/* Control buttons */
.vjs-theme-kubicle .vjs-button > .vjs-icon-placeholder:before {
  color: white;
}

/* Control buttons Icons */
.vjs-theme-kubicle .vjs-button > .vjs-icon-placeholder {
  font-size: 14px;
}

/* Time display */
.vjs-theme-kubicle .vjs-current-time,
.vjs-theme-kubicle .vjs-time-divider,
.vjs-theme-kubicle .vjs-duration {
  color: white;
}

/* Playback rate */
.vjs-theme-kubicle .vjs-playback-rate {
  color: white;
}

/* Playback rate selection fix */
.vjs-theme-kubicle .vjs-playback-rate .vjs-menu-content {
  max-height: none;
}

/* Force display of play button */
.vjs-theme-kubicle .vjs-big-play-button {
  display: block;
  font-size: 4em;
}

/* Add space where progress bar was previously */
.vjs-theme-kubicle .vjs-custom-control-spacer {
  display: block;
  width: 100%;
}

/* Display time as currentTime / Duration */
.vjs-theme-kubicle .vjs-time-control {
  display: block;
  font-size: 14px;
}

.vjs-theme-kubicle .vjs-time-divider {
  padding-left: 0;
  padding-right: 0;
  min-width: 0;
  font-size: 14px;
}

.vjs-theme-kubicle .vjs-remaining-time {
  display: none;
  font-size: 14px;
}

/* Navbar classes added to adjust the width
 when the vertical navbar is opened and closed */
.adminVerticalNavbar {
  .navbarOpen{
    width: 90%;
    position: absolute;
    left: 10%;
  }
  .navbarClose{
    width: 100%;
    position: relative;
  }
}
